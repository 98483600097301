import React from 'react';
import { StoryPage } from '../types/StoryPageType.jsx';
import './StoryBook.css';
import { useState } from 'react';

type StoryBookProps = {
    storyTitle: string;
    storyPages: StoryPage[];
}

const StoryBook = ( {
    storyTitle,
    storyPages
}:StoryBookProps ) => {

    const [currentPageNumber, setCurrentPageNumber] = useState(0);

    const handlePageFlip = (direction: number) => {
        if(direction === -1 && currentPageNumber > 0 || direction === 1 && currentPageNumber < storyPages.length-1){
            setCurrentPageNumber(currentPageNumber + direction);
        }
    }

    return (
        <div className="storyPage">
            <h1 className="storyTitle">{storyTitle}</h1>
            <div className="storyBook">
                <div className="otherPage" onClick={() => handlePageFlip(-1)}>
                    <p className="carrot">{`<`}</p>
                </div>
                <div className="currentPage">
                   <div className="pageText">{
                    storyPages[currentPageNumber].pageText.map((line, lineNumber) => {
                        return <p className='lineText' key={currentPageNumber + "-" + lineNumber}>{line}</p>
                    })
                   }</div>
                </div>
                <div className="otherPage" onClick={() => handlePageFlip(1)}>
                    <p className="carrot">{`>`}</p>
                </div>
            </div>
            <div className="storyBookNavigationBlock">
                <div className="storyBookNavigationBlockLeft" onClick={() => handlePageFlip(-1)}>
                    <img src="./nextPageBookDefault.png" className="storyBookNavigationLeft" />
                    <p className="storyBookNavigationText">Previous Page</p>
                </div>
                <div className="storyBookNavigationBlockRight" onClick={() => handlePageFlip(1)}>
                    <p className="storyBookNavigationText">Next Page</p>
                    <img src="./nextPageBookDefault.png" className="storyBookNavigationRight" />
                </div>  
            </div>
        </div>
    )
}

export default StoryBook;