import React from 'react';
import './SocialMediaIcon.css';

export type SocialMediaIconType = {
    imageSource: string;
    imageAltText: string;
    hyperLink: string;
}

const SocialMediaIcon = ({
    imageSource,
    imageAltText,
    hyperLink
}: SocialMediaIconType) => {
    return <div className='iconContainer'>
        <a href={hyperLink}>
            <img 
                className='iconImage'
                src={imageSource}
                alt={imageAltText}
            />
        </a>
    </div>
}

export default SocialMediaIcon;