import React, { useState } from 'react';
import './ProductPage.css';
import { Product } from '../types/ProductType';

const oldProductListings:Product[] = [{
    title: 'Super Dimension Gaming Purple T-Shirt',
    price: 50.00,
    description: 'Enter a world of games with this Super Dimension Gaming custom shirt! Limited edition. Click the checkout button to see more pictures.',
    imageRef: 'shirt-front-close-purple.jpg',
    squareCheckout: 'https://square.link/u/0dZ0n6fI?src=embed'
}, {
    title: 'Hand Crochet Dice Bag',
    price: 35.00,
    description: 'This is a hand-made dice bag that can easily hold a whole set of dice. We use ours for storing lots of d20s! Our super amazing friend Maddie crochets these. Click the checkout button to see more pictures.',
    imageRef: 'dice-bag.jpg',
    squareCheckout: 'https://square.link/u/y0mJvNn2?src=embed'
}, 
]

const productListings:Product[] = [{
    title: 'Hand Crochet Dice Bag',
    price: 20.00,
    description: 'This is a hand-made dice bag that can easily hold a whole set of dice. We use ours for storing lots of d20s! Our super amazing friend Maddie crochets these. Click the checkout button to see more pictures. Currently only available to buy at THE BLANCO BREW MARKET & WIMBERLEY MARKET DAYS.',
    imageRef: 'dice-bag.jpg',
    squareCheckout: 'https://square.link/u/y0mJvNn2?src=embed'
},
]

const ProductPage = () => {

    const [shoppingList, setShoppingList] = useState<Product[]>([]);

    return(
        <div className="contianer">
            <div className="row">
                <div className='column-12'>
                    <h1>Products Page</h1>
                    {/* <button className="checkout-button">Checkout</button> */}
                    <a href="https://superdimensiongamingstore.square.site/s/shop"><button className="checkout-button">Go To Our Games Shop</button></a>
                </div>
            </div>
            <div className='row'>
                <p>We are currently adding items to this page :)</p>
                <p>Meanwhile, please visit our <a href="https://superdimensiongamingstore.square.site/s/shop">square shop</a> to browse and purchase games.</p>
                <p>We are at the <a href='https://www.google.com/maps/place/Blanco+Brew/@29.9991557,-98.1031759,17z/data=!3m1!4b1!4m6!3m5!1s0x865b5e782cff2d0f:0x8c92eb2ea6649ba3!8m2!3d29.9991511!4d-98.100601!16s%2Fg%2F11fzbvvlpb?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D'>Wimberley Blanco Brew</a> local market every Sunday! You can pick up orders there.</p>
                {
                    productListings.map( (product, productNumber) => (
                        <div key={productNumber} className="column-2 product-card">
                            <img src={product.imageRef} className="product-image" alt={product.title} />
                            <div className="product-card-body">
                                <h2>{product.title}</h2>
                                <h4>{`$${product.price}`}</h4>
                                <p>{product.description}</p>
                                {/* <a href={product.squareCheckout}>
                                    <button className="product-button" onClick={
                                        () => setShoppingList([...shoppingList, product])
                                    }>Square Checkout</button>
                                </a> */}
                            </div>
                        </div>
                    ))
                }
            </div>
            {/* <div className='row padding-row'>
                {JSON.stringify(shoppingList)}
            </div> */}
            {/* <div className='row'>
                <div className='column-12 checkout-sticky-footer'>
                    <button className="checkout-button">Checkout</button>
                </div>
            </div> */}
        </div>
    )
}

export default ProductPage;