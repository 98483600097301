import React from 'react';
import SocialMediaBlock from '../components/SocialMediaBlock.tsx';
import StoryBook from '../components/StoryBook.tsx';
import Header from '../features/Header.tsx';
import { StoryPage } from '../types/StoryPageType.jsx';
import './AboutPage.css';
import EventPage from './EventPage.tsx';

const twoWizardsStory: StoryPage[] = [
    {
        pageNumber: 1,
        pageText: [
            "Once upon a time, two traveling wizards met by happenstance.",
            "A wizard of space and a wizard of time.",
            "They played games together, laughed together, and shared good times together.",
            "Their passion for games soon kindled an inseparable friendship."
        ]
    },
    {
        pageNumber: 2,
        pageText: [
            "Out of all the places the wizard of space could be standing,",
            "Out of all the seconds the wizard of time could be present in,",
            "It was with each other, playing games, where they found happiness.",
            "Was it fate that the universe created this moment?"
        ]
    },
    {
        pageNumber: 3,
        pageText: [
            "But as they played, something strange happened; fate had different plans.",
            "The magics of space and the magics of time that brought them together started to change.",
            "Their magics swirled together creating a rift in the fabric of the universe.",
            "The wizards were suddenly ripped from the world they knew and sent spiraling into a dark void."
        ]
    },
    {
        pageNumber: 4,
        pageText: [
            "Their magics were strong and for once, they were not alone.",
            "The wizards stood together, to face this new unknown.",
            "As they stood, soft glowing magical lamps flickered into life, illuminating a cafe.",
            "It had tables for people to share and a library of board games stretching from the floor to the ceiling."
        ]
    },
    {
        pageNumber: 5,
        pageText: [
            "Opening the front door revealed a deep dark purple void with swirls of magical energy floating about.",
            "The wizards looked at each other with understanding dawning in their eyes.",
            "Together, their strong love for games and the happiness they had together had caused their magics to entangle, ",
            "creating a whole other dimension, the Super Dimension, where they were happy together."
        ]
    },
    {
        pageNumber: 6,
        pageText: [
            "However, their magics allowed them glimpses into the world they had left behind.",
            "There were others like them with a love and passion for playing games with friends.",
            "So the two wizards began weaving a powerful spell together, combing the magics of space and time,",
            "to summon their humble establishment in the world by opening up a portal for others to enter."
        ]
    },
    {
        pageNumber: 7,
        pageText: [
            "Welcome to Super Dimension Gaming!"
        ]
    }
]

const screenWidth = window.matchMedia('(max-width: 800px)');

const About = () => {
    return (
        <div className="aboutPage container">
            <div className='row'>
                <div className={screenWidth.matches ? 'column-12' : 'column-6'}>
                    <div className='row'>
                        <div className={screenWidth.matches ? 'column-12' : 'column-6'}>
                            <img 
                                className='aboutPhoto'
                                src='aboutPhoto.png'
                                alt='Kyle and Josh about us photo'
                            />
                        </div>
                        <div className={screenWidth.matches ? 'column-12' : 'column-6'}>
                            <p className='aboutTextStyling'>
                                Josh and Kyle created Super Dimension to bring together and foster the tabletop gaming community in Wimberley. While we are searching for a location in Wimberley to open our game store, we are hosting one day gaming events throughout Wimberley!
        
                            </p>
                            <p className='aboutTextStyling'>    
            Kyle grew up in Wimberley and is the technology teacher at the Blanco River Academy. He has a Bachelor’s in Computer Science with a minor in Business Administration from Texas State University.
                            </p>
                            <p className='aboutTextStyling'>
            Josh has been running Dungeons and Dragons groups at the Wimberly Village Library and Blanco River Academy for the past 3 years. He has a Bachelor’s in Business Administration from Texas State University.
                            </p>
                            <p className='aboutTextStyling'>
            We have a curated a library of board games, host Dungeons and Dragons campaigns, run Magic: the Gathering drafts, and more to share with the community! Visit us at an upcoming Pop-Up event to get a sample of what we have to offer!
                            </p>
                        </div>
                    </div>
                </div>
                <div className={screenWidth.matches ? 'column-12' : 'column-6'}>
                    <StoryBook 
                        storyTitle="A Tale of Two Wizards"
                        storyPages={twoWizardsStory}
                    />
                </div>
            </div>
            <div className='row'>
                <div className="eventIntroTextGroup">
                    <p className="eventIntroText">The magic to keep the Super Dimension open in this world is difficult.</p>
                    <p className="eventIntroText">While we are combining our magics and working on a spell to keep us here,</p>
                    <p className="eventIntroText">be on the lookout for Pop-Up Portals into the Super Dimension.</p>
                    <p className="eventIntroText">Come play games in the Super Dimension!</p>
                </div>
            </div>
            <div className='row'>
                <EventPage />
            </div>
        </div>
    )
}

export default About;