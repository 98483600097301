import React from 'react';
import ActionText from '../components/ActionText.tsx';
import SocialMediaIcon, { SocialMediaIconType } from '../components/SocialMediaIcon.tsx';
import './SocialMediaAction.css';

const SocialMediaLinks:SocialMediaIconType[] = [
    {
        imageSource: '/super-fb-icon.png',
        imageAltText: 'Our Facebook is Super Dimension Gaming.',
        hyperLink: 'https://www.facebook.com/profile.php?id=61565054824396'
    },
    {
        imageSource: '/super-insta-icon.png',
        imageAltText: 'Our Instagram is SuperDimension.Gaming.',
        hyperLink: 'https://www.instagram.com/superdimension.gaming?igsh=MTl3MTgxdjJwY2VidA%3D%3D&utm_source=qr'
    }
]

const SocialMediaAction = () => {
    return <div>
        <ActionText />
        <div className='icon-group-container'>
            {SocialMediaLinks.map(mediaLink => {
                return <div className='icon-container'>
                    <SocialMediaIcon
                        imageSource={mediaLink.imageSource}
                        imageAltText={mediaLink.imageAltText}
                        hyperLink={mediaLink.hyperLink}
                    />
                </div>
            })}
        </div>
    </div>
}

export default SocialMediaAction;