import React, { useState } from 'react';
import './TopNav.css';
import '../css/grid.css';
import { Link } from 'react-router-dom';

export enum LinkText {
    HOME = 'Home',
    EVENTS = 'Events',
    ABOUT = 'About Us',
    SHOP = 'Shop'
}

export enum LinkPath {
    HOME = '/Home',
    EVENTS = '/Events',
    ABOUT = '/About',
    SHOP = '/Shop'
}

type Links = {
    linkPath: string;
    linkText: LinkText;
}

const links:Links[] = [
    {
        linkPath: LinkPath.HOME,
        linkText: LinkText.HOME
    }, {
        linkPath: LinkPath.EVENTS,
        linkText: LinkText.EVENTS
    }, {
        linkPath: LinkPath.ABOUT,
        linkText: LinkText.ABOUT
    }, {
        linkPath: LinkPath.SHOP,
        linkText: LinkText.SHOP
    }, 
]

// const screenWidth = window.innerWidth;
const screenWidth = window.matchMedia('(max-width: 800px)');

const TopNav = () => {
    const [openNav, setOpenNav] = useState(false);

    return <div className="container top-nav">
        {
            screenWidth.matches ? 
            <div className="row">
                {
                    openNav ? 
                        <div className='container'>
                            <nav className="column-6">
                                {
                                    links.map( (link, linkNumber) => {
                                        return <div className='row'>
                                            <div className="column-12" key={linkNumber}>
                                                <Link className="column-1 nav-links nav-links-mobile" to={link.linkPath}>{link.linkText}</Link>
                                            </div>
                                        </div>
                                    })
                                } 
                                <div className="row">
                                    <div 
                                        className="column-12 mobile-nav-menu-block"
                                        onClick={() => setOpenNav(!openNav)}
                                    >
                                        <img 
                                            className="mobile-nav-menu-icon"
                                            src="./super-menu-icon.png"
                                            alt="Navigation menu icon."
                                        />
                                        <p className="mobile-nav-menu-text">Menu</p>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    :
                    <div className="container">
                        <div className="row">
                            <div 
                                className="column-12 mobile-nav-menu-block"
                                onClick={() => setOpenNav(!openNav)}
                            >
                                <img 
                                    className="mobile-nav-menu-icon"
                                    src="./super-menu-icon.png"
                                    alt="Navigation menu icon."
                                />
                                <p className="mobile-nav-menu-text">Menu</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        :
        <nav className="row">
            {
                links.map( (link, linkNumber) => {
                    return <Link className="column-1 nav-links" to={link.linkPath}>{link.linkText}</Link>
                })
            }
        </nav>
        }
    </div>
}

export default TopNav;