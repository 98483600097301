import React from 'react';
import { useState } from "react";
import './MoreInfoActionButton.css';

const MoreInfoActionButton = () => {

    const [isMoreInfoToggled, setIsMoreInfoToggled] = useState(false);



    return (
        <div onClick={ () => { setIsMoreInfoToggled(true) } } className="moreInfoActionButton">
            <img src={'downArrow.png'} alt="More info action dropdown icon." className="moreInfoIcon" />
            <p className="moreInfoText">More Info</p>
        </div>
    )
}
//<a href="https://www.flaticon.com/free-icons/arrow" title="arrow icons">Arrow icons created by th studio - Flaticon</a>
export default MoreInfoActionButton;